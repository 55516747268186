import * as React from 'react'
import { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react'
import { CtaSimple } from '../components/ctaSimple'
import { NavBar } from '../components/navbar'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'
import Layout from '../components/layout'
import Seo from '../components/seo'

/*
  TEMP NOTE: If you update the form fields, you must also update the shadow form
  on the /thanks/ page to prevent processing issus with Netlify

  ANOTHER TEMP NOTE: The shadow form does not yet exist but will be added separately
  It is used to capture into Netlify anything flagged as spam by Recaptcha for review
  It will be removed once we are confident in the threshold setting that we aren't
  trapping real contact forms
*/

const ContactPage = () => {
  // @TODO replace with dynamic value after contentful is implemented
  const thanksPageSlug = '/thanks/'

  const [sessionUrl, setSessionUrl] = useState("");

  useEffect(() => {
    function handleFsReady() {
      const url = FS.getCurrentSessionURL();
      setSessionUrl(url);
    }

    if (window['_fs_ready']) {
      handleFsReady();
    } else {
      window.addEventListener("fsReady", handleFsReady);
    }

    return () => {
      window.removeEventListener("fsReady", handleFsReady);
    };
  }, []);

  const [disable, setDisable] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifySubmission = async () => {
    if (!executeRecaptcha) {
      return
    }

    setDisable(true)

    try {
      const contactForm = document.getElementById('contactForm');
      const formData = new FormData(contactForm)

      const token = await executeRecaptcha('contact')

      await fetch('/.netlify/functions/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        })
      })
      .then(response => {
        // @todo should we do something else in this case of 500 errors?
        // @todo temporary send to a separate form bucket to make sure we aren't being too aggressive with threshold
        // also remove the form from netlify and the thanks page when satisfied
        if (response.status === 500 || response.status === 400) {
          formData.set('form-name', 'recaptured-contact')
        }

        return response.json()
      })
      .then(data => formData.set('score', data.score))

      const trashDescriptions = ['hello', 'yes', 'yet', 'test'];

      if (!trashDescriptions.includes(formData.get('project').trim().toLowerCase())) {
        sendSubmission(formData)
      } else {
        navigate(thanksPageSlug)
      }
    } catch (e) {
      console.log(e)
      setDisable(false)
    }
  }

  const sendSubmission = (data) => {
    let encodedName = ""
    let encodedEmail = ""

    if (typeof window !== "undefined") {
      encodedName = window.btoa(data.get('name'))
      encodedEmail = window.btoa(data.get('email'))
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body:  new URLSearchParams(data).toString()
    })
    .then(() => navigate(thanksPageSlug + '?n=' + encodedName + '&e=' + encodedEmail))
  }

  const handleSubmit = e => {
    e.preventDefault()
    verifySubmission()
  }

  // Implementing input styles is a bit more complex than the rest of the chakra elements, so this work around is necessary to make the fields look correct.
  // See: https://github.com/chakra-ui/chakra-ui/issues/2347
  const inputStyles = {
    height: '52px',
    border: 'none',
    borderBottom: '2px solid rgb(69,69,69)',
    background: 'rgba(255,255,255,0.2)',
    borderRadius: '0',
    p: '4',
    _focus: {
      color: 'rgb(23,24,33)',
      background: 'white',
      borderBottom: '2px solid #970725',
    },
  }

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Box
        bgImage={fractalBg}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        <NavBar />
        <CtaSimple
          title="Ready to level up your devs?"
          helperText="Fill out this form and we'll be in touch with pricing and next steps."
          titleSize="3xl"
          pageHeader
        />
      </Box>
      <Box
        as="section"
        maxW="xl"
        mx="auto"
        px={{
          base: 4,
          md: 8,
        }}
        py={{
          base: 10,
          md: 16,
        }}
      >
        <VStack spacing={10} alignItems="flex-end" width="100%" mt={12}>
          <form
            id="contactForm"
            name="contact"
            method="POST"
            netlify-honeypot="verify"
            data-netlify="true"
            onSubmit={handleSubmit}
            action={thanksPageSlug}
            style={{ display: 'flex', flexDirection: 'column', width: '100%'}}
          >
            <Input type="hidden" name="form-name" value="contact" />
            <Input type="hidden" name="score" value="" />
            <Input type="hidden" name="session" value={sessionUrl} />
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">Your Name</FormLabel>
              <Input name="name" placeholder="Your Name" variant="" fontSize="md" {...inputStyles} />
            </FormControl>
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">Company Name</FormLabel>
              <Input name="company" placeholder="Company Name" variant="" fontSize="md" {...inputStyles} />
            </FormControl>
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">Email Address</FormLabel>
              <Input name="email" placeholder="Email Address" variant="" fontSize="md" {...inputStyles} />
            </FormControl>
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">Phone Number</FormLabel>
              <Input name="phone" placeholder="Phone Number" variant="" fontSize="md" {...inputStyles} />
            </FormControl>
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">What are your testing needs?</FormLabel>
              <Select
                name="needs"
                placeholder="Select One..."
                size="lg"
                variant="filled"
                bg="rgba(255,255,255,0.2)"
                borderRadius="0"
                fontSize="md"
                _hover={{
                  bg: 'rgba(255,255,255,0.2)',
                  borderBottom: '2px solid brand.primary'
                }}
                _focus={{
                  bg: 'rgba(255,255,255,0.2)',
                  borderBottom: '2px solid brand.primary',
                }}
                sx={{
                  "> option": {
                    background: '#171821',
                  },
                }}
              >
                <option value="Desktop">Desktop</option>
                <option value="Mobile">Mobile</option>
                <option value="Both">Both</option>
                <option value="Other">Other</option>
                <option value="Unsure">Unsure</option>
              </Select>
            </FormControl>
            <FormControl isRequired mb={8}>
              <FormLabel fontWeight="700">Tell us about your project needs</FormLabel>
              <Textarea
                name="project"
                placeholder="Message"
                resize="vertical"
                size="lg"
                variant="filled"
                bg="rgba(255,255,255,0.2)"
                borderRadius="0"
                fontSize="md"
                minHeight="200px"
                _hover={{
                  bg: 'rgba(255,255,255,0.2)',
                  borderBottom: '2px solid brand.primary'
                }}
                _focus={{
                  bg: 'rgba(255,255,255,0.2)',
                  borderBottom: '2px solid brand.primary'
                }}
              />
            </FormControl>
            <FormControl>
              <VisuallyHidden>
                {/* {getCopy(content, 'component.contactForm.verify')} */}
                <Input type="text" name="verify" />
              </VisuallyHidden>
            </FormControl>
            <Button
              type="submit"
              isDisabled={disable}
              variant="base"
              size="lg"
              mb={12}
              _hover={{
                backgroundColor: '#70081E',
                textShadow: '1px 0 10px #fff',
              }}
            >
              Send
            </Button>
          </form>
        </VStack>
      </Box>
    </Layout>
  )
}

export default ContactPage
